import React from "react";

import { SvgIcon, useTheme } from "@material-ui/core";

export default function BackgroundDesktop() {
  const { palette } = useTheme();

  return (
    <SvgIcon sx={{ height: "100%", width: "100%" }} viewBox="0 0 579 581">
      <linearGradient id="a" x1="46.852531%" x2="56.224844%" y1="-13.199555%" y2="107.340012%">
        <stop offset="0" stopColor={palette.primary.main} />
        <stop offset="1" stopColor={palette.primary.light} />
      </linearGradient>
      <path
        d="m1393 764.803802c284.42665 0 498 35.921675 498-182.790457 0-218.712131-230.57335-396.013345-515-396.013345s-515 177.301214-515 396.013345c0 218.712132 247.57335 182.790457 532 182.790457z"
        fill="url(#a)"
        fillRule="evenodd"
        transform="translate(-861 -186)"
      />
    </SvgIcon>
  );
}
