import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@material-ui/core";
import { GatsbyImage } from "gatsby-plugin-image";

CardBusinessSegment.propTypes = {
  image: PropTypes.object,
  text: PropTypes.string,
};
export default function CardBusinessSegment({ image, text }) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box>
        <GatsbyImage
          alt={image?.alternativeText || ""}
          image={image?.localFile.childImageSharp.gatsbyImageData}
          style={{
            pointerEvents: "none",
            borderRadius: "8px",
            overflow: "hidden",
            transform: "translateZ(0)", // HACK: https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          width: "100%",
          position: "absolute",
          top: 16,
        }}
      >
        <Box
          sx={{
            bgcolor: "hsla(0, 0%, 100%, 88%)",
            maxWidth: "80%",
            p: { xs: 2, md: 3 },
            zIndex: 5,
          }}
        >
          <Typography component="div" sx={{ fontWeight: 700 }} variant="subtitle2">
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
