import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';

import { Box, Button, Container, Typography } from '@material-ui/core';
import { HelpVideo, ApplicationContext } from '@product-site-frontend/shared';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

import arenzaSmile from '../assets/svg/arenza-smile.svg';
import BackgroundDesktop from './BackgroundDesktop';

function Subscript(props) {
  return (
    <Typography
      component="span"
      sx={{ fontSize: "0.75em" }}
      variant="inherit"
      {...props}
    />
  );
}

function Caption(props) {
  return (
    <Typography
      component="div"
      sx={{ color: "text.secondary", lineHeight: 1.4 }}
      variant="caption"
      {...props}
    />
  );
}

SectionMain.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    bgDesktop: PropTypes.object,
    bgMobile: PropTypes.object,
    term: PropTypes.number,
    dealAmount: PropTypes.number,
    advanceRate: PropTypes.number,
  }),
};

export default function SectionMain({ content }) {
  const { search } = useLocation();

  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);

  useEffect(() => {
    let query = new URLSearchParams(search);

    const showModalLeasing = query.get("showModalLeasing");
    if (showModalLeasing) {
      handleApplicationClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplicationClick = useCallback(() => {
    setAmount('');
    setApplicationOpen(true);
  }, [setApplicationOpen, setAmount]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('short_app')) {
      setApplicationOpen(true);
    }
  }, [setApplicationOpen]);

  return (
    <Box
      component="section"
      mb={{ xs: 3, lg: 6 }}
      overflow="hidden"
      position="relative"
    >
      <Box
        sx={{
          bottom: -340,
          left: 720,
          position: "absolute",
          right: 0,
          width: 720,
          display: { xs: "none", lg: "block" },
        }}
      >
        <BackgroundDesktop />
      </Box>
      <Box
        alt="Логотип Arenza"
        component="img"
        src={arenzaSmile}
        sx={{
          display: { xs: "none", lg: "block" },
          width: 160,
          position: "absolute",
          right: "16px",
          bottom: "16px",
        }}
      />
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          mt={{ lg: 8 }}
        >
          <Box
            sx={{
              maxWidth: "640px",
              mt: { xs: 4, lg: 0 },
              mb: { xs: 2, lg: 0 },
              width: { xs: 1, lg: 0.6 },
            }}
          >
            <Typography component="div" sx={{ mb: { xs: 4, lg: 6 } }} variant="h1">
              {parse(content.title)}
            </Typography>
            <Typography component="div" sx={{ mb: { xs: 4, lg: 6 } }} variant="subtitle1">
              {parse(content.description)}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              maxWidth={600}
              mb={{ xs: 4, lg: 7 }}
              overflow="auto"
              pb={2}
            >
              <Box>
                <Typography component="div" variant="h2">
                  <Subscript>до</Subscript>
                  &nbsp;{content.dealAmount}
                  <Subscript>млн</Subscript>
                </Typography>
                <Caption>руб. сумма сделки</Caption>
              </Box>
              <Box ml={5}>
                <Typography component="div" variant="h2">
                  <Subscript>от</Subscript>
                  &nbsp;{content.advanceRate}
                  <Subscript>%</Subscript>
                </Typography>
                <Caption>авансовый платеж</Caption>
              </Box>
              <Box sx={{ ml: 5 }}>
                <Typography component="div" variant="h2">
                  <Subscript>до</Subscript>
                  &nbsp;
                  {content.term}
                  <Subscript>мес</Subscript>
                </Typography>
                <Caption>срок финансирования</Caption>
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap={{ xs: "wrap", md: "nowrap" }}
              justifyContent={{ xs: "center", lg: "normal" }}
            >
              <Box
                sx={{
                  maxWidth: 375,
                  mb: { xs: 3, lg: 0 },
                  width: { xs: 1, lg: "auto" },
                  py: 2,
                }}
              >
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleApplicationClick}
                  size="large"
                  variant="contained"
                >
                  Подать заявку
                </Button>
              </Box>
              <Box maxWidth={375} ml={{ lg: 3 }} width={{ xs: 1, lg: "auto" }}>
                <HelpVideo
                  videoText={
                    <>
                      узнайте все о лизинге
                      <br />
                      за 80 секунд
                    </>
                  }
                  videoUrl="https://www.youtube.com/watch?v=VJ1wK48wOQs"
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              width: { lg: 0.5 },
              mx: { xs: -3, md: -4, lg: 0 },
            }}
          >
            <Box sx={{ display: { lg: "none" } }}>
              {content.bgMobile ? (
                <Box
                  alt={content.bgMobile.alternativeText || ""}
                  component={GatsbyImage}
                  image={content.bgMobile.localFile.childImageSharp.gatsbyImageData}
                  sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              ) : (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundImage: (theme, second) =>
                        `linear-gradient(119deg, ${theme.palette.primary.main} 23%, ${theme.palette.primary.light} 90%)`,
                    }}
                  />
                  <Box
                    alt="Arenza"
                    bottom={16}
                    component="img"
                    position="absolute"
                    right={0}
                    src={arenzaSmile}
                    width={72}
                  />
                </>
              )}
            </Box>
            <Box
              alt={content.bgDesktop.alternativeText || ""}
              component={GatsbyImage}
              image={content.bgDesktop.localFile.childImageSharp.gatsbyImageData}
              objectFit="contain"
              sx={{
                width: "90%",
                ml: { xs: 0, md: "10%" },
                maxHeight: { xs: 200, md: 256, lg: 520 },

                "& img": {
                  objectFit: "contain !important",
                },
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
